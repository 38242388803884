import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <div className="footer">
            <div className="powered">Powered by Coin Gecko</div>
        </div>
    );
};

export default Footer;
